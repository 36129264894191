<template>
    <tr>
        <td>{{ indice }}º</td>
        <td>
            <img :src="url" />
        </td>
        <td>
            <div style="float: right">
                <button
                    v-if="ativado"
                    @click="desativar"
                    type="button"
                    class="btn btn-dark btn-sm mx-2 largura-botao"
                >
                    Desativar
                </button>

                <button
                    v-if="!ativado"
                    @click="ativar"
                    type="button"
                    class="btn btn-warning btn-sm mx-2 largura-botao"
                >
                    Ativar
                </button>
                <button
                    type="button"
                    @click="deletar"
                    class="btn btn-danger btn-sm mx-2 largura-botao"
                >
                    Deletar
                </button>
            </div>
        </td>
    </tr>
</template>

<script>
import axiosApi from "@/axios";
import store from "@/store";
export default {
    name: "ItemCategoria",
    props: {
        indice: Number,
        id: Number,
        url: String,
        ativo: Boolean,
    },
    data() {
        return {
            ativado: false,
        };
    },

    methods: {
        async desativar() {
            try {
                store.commit("setLoading", true);
                let dados = { ativo: false, url_slider: this.url };
                await axiosApi.put(`/admin/slider/${this.id}`, dados);
                this.ativado = false;
            } catch (error) {
                this.$swal("Erro", "Ocorreu um erro transacional", "error");
            } finally {
                store.commit("setLoading", false);
            }
        },

        async ativar() {
            try {
                store.commit("setLoading", true);
                let dados = { ativo: true, url_slider: this.url };
                await axiosApi.put(`/admin/slider/${this.id}`, dados);
                this.ativado = true;
            } catch (error) {
                this.$swal("Erro", "Ocorreu um erro transacional", "error");
            } finally {
                store.commit("setLoading", false);
            }
        },
        async deletar() {
            try {
                store.commit("setLoading", true);
                await axiosApi.delete(`/admin/slider/${this.id}`);
                this.$emit("on-deletar");
            } catch (error) {
                this.$swal("Erro", "Ocorreu um erro transacional", "error");
            } finally {
                store.commit("setLoading", false);
            }
        },
    },

    mounted() {
        this.ativado = this.ativo;
    },
};
</script>

<style>
.largura-botao {
    min-width: 100px;
}
</style>