<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="row">
                <div class="col-12 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title">Configurações</h4>
                            <p class="card-description">Alterar usuário</p>
                            <div>
                                <form class="forms-sample row">
                                    <div class="form-group col-md-5">
                                        <label for="usuario">Usuário</label
                                        ><input
                                            type="text"
                                            id="usuario"
                                            class="form-control"
                                            v-model="configuracao.usuario"
                                        />
                                        <div
                                            v-if="erros.usuario"
                                            class="invalid-feedback"
                                        >
                                            {{ erros.usuario }}
                                        </div>
                                    </div>
                                    <div class="form-group col-md-5">
                                        <label for="senha">Senha</label>

                                        <input
                                            type="password"
                                            id="senha"
                                            class="form-control"
                                            v-model="configuracao.senha"
                                        />
                                        <div
                                            v-if="erros.senha"
                                            class="invalid-feedback"
                                        >
                                            {{ erros.senha }}
                                        </div>
                                    </div>

                                    <div class="col-12 col-md-2">
                                        <button
                                            type="button"
                                            class="
                                                btn btn-success
                                                me-2
                                                float-end
                                                mb-4
                                                mt-4
                                            "
                                            @click.prevent="alterarUsuario()"
                                        >
                                            Alterar
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-12 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title">Banners</h4>
                            <div class="mt-3 mb-5">
                                <input
                                    type="file"
                                    id="upload"
                                    @change="escolherArquivo"
                                    ref="file"
                                    accept="image/*"
                                    hidden
                                />
                                <label class="upload" for="upload"
                                    >Adicionar banner</label
                                >
                            </div>
                            <div class="table-responsive">
                                <table class="table table-striped">
                                    <tbody>
                                        <item-banners
                                            @on-deletar="deletar"
                                            v-for="(item, index) in banners"
                                            :key="item.id"
                                            :indice="++index"
                                            :id="item.id"
                                            :url="item.url_slider"
                                            :ativo="item.ativo"
                                        />
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import axiosApi from "@/axios";
import store from "@/store";
import ItemBanners from "../components/ItemBanners.vue";

export default {
    name: "Configuracao",
    components: {
        ItemBanners,
    },
    data() {
        return {
            configuracao: {
                usuario: "",
                senha: "",
            },
            erros: [],
            banners: [],
            imagemUpload: "",

            imagem: null,
        };
    },
    methods: {
        resetar() {
            this.imagemUpload = "";
        },

        escolherArquivo() {
            this.imagem = this.$refs.file.files[0];

            const formData = new FormData();
            formData.append("slider_imagem", this.imagem);
            const headers = { "Content-Type": "multipart/form-data" };

            store.commit("setLoading", true);
            axiosApi
                .post("/admin/slider/upload", formData, { headers })
                .then((response) => {
                    this.imagemUpload = response.data.link;
                    this.subirArquivo();
                })
                .catch((error) => {
                    this.$swal("Upload", error.response.data.erro, "error");
                })
                .then(() => {
                    store.commit("setLoading", false);
                });
        },

        async subirArquivo() {
            try {
                store.commit("setLoading", true);
                let data = {
                    url_slider: this.imagemUpload,
                    ativo: true,
                };
                await axiosApi.post("/admin/slider", data);
                this.resetar();
                this.listarBanners();
                await this.$swal("Sucesso", "Banner adicionado", "success");
            } catch (error) {
                this.$swal("Erro", "Ocorreu um erro transacional", "error");
            } finally {
                store.commit("setLoading", false);
            }
        },

        async alterarUsuario() {
            this.erros = [];
            try {
                store.commit("setLoading", true);
                let data = {
                    senha: this.configuracao.senha,
                    usuario: this.configuracao.usuario,
                };
                await axiosApi.put("/admin/usuario/1", data);
                await this.$swal(
                    "Sucesso",
                    "Usuário alterado com sucesso",
                    "success"
                );
                this.configuracao = {
                    usuario: "",
                    senha: "",
                };
            } catch (error) {
                this.erros = error.response.data.erros;
            } finally {
                store.commit("setLoading", false);
            }
        },

        async listarBanners() {
            try {
                store.commit("setLoading", true);

                const response = await axiosApi.get("/admin/slider");
                this.banners = response.data.itens;
            } catch (error) {
                this.$swal("Erro", "Ocorreu um erro transacional", "error");
            } finally {
                store.commit("setLoading", false);
            }
        },

        async deletar() {
            await this.listarBanners();
        },
    },
    mounted() {
        this.listarBanners();
    },
};
</script>

<style lang="scss">
label.upload {
    display: inline-block;
    background-color: #1f3bb3;
    color: white;
    padding: 0.5rem;
    font-family: sans-serif;
    border-radius: 0.3rem;
    cursor: pointer;
    margin-top: 1rem;
    font-size: 13px;
    padding: 14px;
    font-weight: bold;
}
.form-select {
    height: 33px;
    padding: 0px 10px;
}
.invalid-feedback {
    display: block;
    font-size: 12px;
}
.table td img {
    width: auto;
    height: 64px;
    border-radius: 0;
}
</style>